.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0.6rem;
}
.page .img-bg {
  width: 100%;
  height: 3.64rem;
}
.page .title {
  padding-left: 0.52rem;
  font-size: 0.4rem;
  color: #000000;
  font-weight: bold;
  margin-top: 0.3rem;
}
.page .form {
  padding: 0 0.56rem 0 0.54rem;
  margin-bottom: 0.2rem;
}
.page .form .cell {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 0.52rem;
  padding-top: 0.4rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #e1e1e1;
}
.page .form .cell .icon {
  position: absolute;
  top: 0.44rem;
  left: 0;
  display: inline-block;
  width: 0.32rem;
  height: 0.32rem;
  z-index: 2;
}
.page .form .cell input {
  width: 100%;
  height: 0.4rem;
  font-size: 0.28rem;
  color: #333;
  line-height: 0.4rem;
}
.page .form .cell input::-webkit-input-placeholder {
  color: #b3b3b3;
}
.page .form .cell .icon-mobile {
  width: 0.24rem;
  background-image: url('../../assets/icon_denglu_shouji@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .page .form .cell .icon-mobile {
    background-image: url('../../assets/icon_denglu_shouji@3x.png');
  }
}
.page .form .cell .icon-password {
  width: 0.28rem;
  background-image: url('../../assets/icon_denglu_mima@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .page .form .cell .icon-password {
    background-image: url('../../assets/icon_denglu_mima@3x.png');
  }
}
.page .form .cell .icon-img-code {
  height: 0.28rem;
  background-image: url('../../assets/icon_denglu_yanzhengma@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .page .form .cell .icon-img-code {
    background-image: url('../../assets/icon_denglu_yanzhengma@3x.png');
  }
}
.page .form .cell .icon-sms-code {
  background-image: url('../../assets/icon_denglu_shuruyanzhengma@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .page .form .cell .icon-sms-code {
    background-image: url('../../assets/icon_denglu_shuruyanzhengma@3x.png');
  }
}
.page .form .cell .img-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 2.4rem;
  height: 0.8rem;
  z-index: 4;
}
.page .form .cell .img-box .img-code {
  width: 100%;
  height: 100%;
}
.page .form .cell .send-btn,
.page .form .cell .countdown {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1.68rem;
  height: 0.52rem;
  font-size: 0.24rem;
  color: #fff;
  line-height: 0.52rem;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.26rem;
  z-index: 4;
}
.page .form .cell .send-btn {
  background: var(--main-color);
}
.page .form .cell .send-btn:active {
  color: #fff;
  background-color: rgba(16, 143, 244, 0.7);
}
.page .form .cell .countdown {
  background: #999;
}
.page .form-bottom {
  position: relative;
  display: flex;
  height: 0.34rem;
  padding-left: 0.56rem;
  padding-right: 0.54rem;
}
.page .form-bottom .forget-pwd-link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.54rem;
  font-size: 0.24rem;
  color: #333;
  line-height: 0.34rem;
  white-space: nowrap;
  z-index: 2;
}
.page .submit-btn {
  width: 6.4rem;
  height: 0.88rem;
  margin: 0 auto;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.88rem;
  text-align: center;
  white-space: nowrap;
  background-color: var(--main-color);
  border-radius: 0.5rem;
  overflow: hidden;
}
.page .submit-btn:active {
  opacity: 0.7;
}
